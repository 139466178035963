import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, TemplateRef } from '@angular/core';
import { toggleExpandAnimation } from 'src/app/shared/classes/animations';

@Component({
  selector: 'results-list-header-filter',
  templateUrl: './results-list-header-filter.component.html',
  animations: [toggleExpandAnimation],
  styleUrls: ['./results-list-header-filter.component.scss']
})
export class ResultsListHeaderFilterComponent {
  @Input() title = '';
  @Input() filterTemplate: TemplateRef<any> | undefined;
  showFilters = false;
  showPopover = false;

  constructor (private readonly breakpointObserver: BreakpointObserver, private readonly cd: ChangeDetectorRef) {
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        // reset booleans
        this.showFilters = false;
        this.showPopover = false;
      }
    });
  }

  // @HostListener('click')
  // @HostListener('keydown.enter')
  // @HostListener('keydown.space')
  public toggle (): void {
    this.showFilters = !this.showFilters;
    this.cd.detectChanges();
  }

  showOverlay (): void {
    this.showPopover = !this.showPopover;
    this.toggle();
  }
}
