import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LoggerService } from '../../services/logger/logger.service';
import { environment } from '../../../../environments/environment';

export interface IRequestOptions {
  headers?: HttpHeaders
  observe?: 'body'
  params?: HttpParams
  responseType?: 'json'
  withCredentials?: boolean
  body?: any
}

@Injectable()
export class AppHttpClientService {
  private static readonly _showLoader = false;

  constructor (private readonly http: HttpClient, private readonly logger: LoggerService) { }

  public get<T>(endPoint: string, showLoader = true, options?: IRequestOptions): Observable<T> {
    return this.http.get<T>(environment.baseApiUrl + endPoint, options)
      .pipe(
        tap((response: T) => { this.logger.log('Response on GET request: ' + JSON.stringify(response, undefined, 4)); }),
        finalize(() => {
          setTimeout(() => {
            console.log('finished');
          }, 5000);
        })
      );
  }

  public post<T>(endPoint: string, body: object, showLoader = true, options?: IRequestOptions): Observable<T> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: options?.params };

    if (options === null) {
      options = httpOptions;
    }

    return this.http.post<T>(environment.baseApiUrl + endPoint, JSON.stringify(body), options)
      .pipe(
        tap((response: T) => { console.log(JSON.stringify(response)); }),
        finalize(() => {
          console.log('finished');
        })
      );
  }

  public put<T>(endPoint: string, params: object, showLoader = true, options?: IRequestOptions): Observable<T> {
    if (showLoader) alert('Loader will display here');
    return this.http.put<T>(environment.baseApiUrl + endPoint, params, options)
      .pipe(
        tap((response: T) => { console.log(JSON.stringify(response)); }),
        finalize(() => {
          console.log('finished');
        })
      );
  }

  public delete<T>(endPoint: string, showLoader = true, options?: IRequestOptions): Observable<T> {
    if (showLoader) alert('Loader will display here');
    return this.http.delete<T>(environment.baseApiUrl + endPoint, options)
      .pipe(
        tap((response: T) => { console.log(JSON.stringify(response)); }),
        finalize(() => {
          console.log('finished');
        })
      );
  }
}
