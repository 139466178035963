<mat-sidenav-container>
    <mat-sidenav #sideNav class="faq" mode="over">
        <anthem-global-faq *ngIf="showGlobalFaq"></anthem-global-faq>
</mat-sidenav>
    <mat-sidenav-content>
        <anthem-header></anthem-header>
        <router-outlet></router-outlet>
        <app-toast></app-toast>
        <anthem-footer></anthem-footer>
    </mat-sidenav-content>
</mat-sidenav-container>