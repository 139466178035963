import { Component } from '@angular/core';
import { GlobalFaqService } from '../global-faq/global-faq.service';
import { UserConfigurationService } from 'src/app/auth/services/user-configuration.service';
import { Router } from '@angular/router';

@Component({
  selector: 'anthem-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isMenuCollapsed = true;
  constructor (private readonly router: Router, private readonly faqService: GlobalFaqService, public userConfigService: UserConfigurationService) { }

  showFaq (): void {
    this.faqService.toggleFaqMenu(true);
  }

  navigateHome (): void {
    this.router.navigate([`/${this.userConfigService.getClientKey()}/provider-search`]);
  }

  showSpacer (): boolean {
    return this.userConfigService.networkLogo() !== '' && this.userConfigService.clientLogo() !== '';
  }
}
