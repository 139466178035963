import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { UserCredentialsService } from './services/user-credentials.service';
import { UserConfigurationService } from './services/user-configuration.service';
import { HttpErrorCodes } from './auth.model';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor (private readonly userCredentialsService: UserCredentialsService, private readonly configurationService: UserConfigurationService) {}

  intercept (request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('/login')) return next.handle(request);

    return next.handle(this.authenticateRequest(request)).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === HttpErrorCodes.UNAUTHORIZED) {
          this.userCredentialsService.removeToken();
          location.reload();
          return throwError(() => err);
        }
        return throwError(() => err);
      })
    );
  }

  private authenticateRequest (req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.userCredentialsService?.getToken() ?? ' '}`,
        'X-CONFIGURATION-KEY': `${this.configurationService.getClientKey()}`
      }
    });
  }
}
