import { Component } from '@angular/core';

@Component({
  selector: 'search-autcomplete-filter',
  templateUrl: './search-autcomplete-filter.component.html',
  styleUrls: ['./search-autcomplete-filter.component.scss']
})
export class SearchAutcompleteFilterComponent {

}
