<section class="page">
        <sub-header [headerName]="'Provider Directory'" [linkHref]="'/provider-results'" [linkText]="'Back'"></sub-header>   
        <!-- Sub Header  -->
     <section>
        <div class="row page__sub-header justify-content-center">
            <div class="col-lg-10 col-sm-12 d-flex justify-content-between px-5">
               <div class="info-box d-flex">
                <img width="100px" height="100px" class="me-2 icon  d-none d-md-block" src="../../../assets/Svg/ProfileIcon_Provider.svg" alt="">
                 <h2 class="my-auto "> Francini, Alexander, MD</h2>
               </div>
               <div class="buttons d-block">
                <button class="button button--green button--medium-icon mb-4 d-block p4"><img src="../../../assets/Svg/Anthem_button_icon_share.svg" class="me-1" alt="Send to mobile"> Send to Mobile</button>
                <button class="button button--green button--medium-icon d-block p4"><img src="../../../assets/Svg/Anthem__button_comment_icon.svg" class="me-1" alt="Send to mobile"> Report Provider Data</button>
               </div>
            </div>
        </div>
     </section>

  <section class="container-fluid tabs g-0">
    <div class="row">
            <mat-tab-group  dynamicHeight="true" animationDuration="0ms">
                <mat-tab>
                  <ng-template mat-tab-label><h1>Contact Information</h1></ng-template>
                    <ng-container *ngIf="loading">
                        <anthem-skeleton-template [template]="skeletonTemplateEnum.DETAILS"></anthem-skeleton-template>
                    </ng-container>
                    <ng-container *ngIf="!loading">
                        <div class="row justify-content-center">
                            <div class="col-10 details">
                                <div class="row">
                                   <div class="details__box col-lg-4 col-12">
                                     <h1 class="label">This Location</h1>
                                     <div class="content">
                                        <ul class="list p4">
                                            <li>Premier Orthopedics</li>
                                            <li>1401 Pulaski Hwy</li>
                                            <li class="mb-3">Meadowbrook, CA 92570</li>
                                            <li>2.7 Miles <a>Get directions</a></li>
                                        </ul>                      
                                     </div>
                                   </div>
                                </div>
                                <div class="row">
                                    <div class="details__box col-lg-4 col-12">
                                      <div class="content">
                                        <img class="img-fluid" src="../../../assets/Svg/map.png" alt="">                 
                                      </div>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="details__box col-lg-4 col-12">
                                      <h1 class="label">Phone</h1>
                                      <div class="content p4">
                                        <a href="tel:+2153288763">(215) 328 8763</a>                     
                                      </div>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="details__box col-lg-4 col-12">
                                      <h1 class="label">Fax</h1>
                                      <div class="content p4">
                                        <a href="tel:+2153288763">(215) 328 8763</a>                     
                                      </div>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="details__box col-lg-4 col-12">
                                      <h1 class="label">Email</h1>
                                      <div class="content p4">
                                        <a href="mailto:">email@address.com</a>                
                                      </div>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="details__box col-lg-4 col-12">
                                      <h1 class="label">Office Hours</h1>
                                      <div class="content p4">
                                        <ul class="list">
                                            <li>Monday 7AM - 4PM</li>
                                            <li>Tue-Thu 7AM - 4PM</li>
                                            <li>Friday 7AM - 4PM</li>
                                        </ul>                   
                                      </div>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="details__box col-lg-4 col-12">
                                      <h1 class="label">Handicap Accessible</h1>
                                      <div class="content p4">
                                        <p>Yes</p>                     
                                      </div>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="details__box col-lg-4 col-12">
                                      <h1 class="label">Telehealth Appointments Available</h1>
                                      <div class="content p4">
                                        <p>Yes</p>                   
                                      </div>
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="details__box col-lg-4 col-12">
                                      <h1 class="label">Languages Spoken</h1>
                                      <div class="content p4">
                                        <ul class="list">
                                            <li>English</li>
                                            <li>Spanish</li>
                                        </ul>                    
                                      </div>
                                    </div>
                                 </div>
                            </div>
                        </div>
                    </ng-container>

                  </mat-tab>
                  <mat-tab>
                    <ng-template mat-tab-label><h1>Profile</h1></ng-template>
                    <div class="row justify-content-center">
                        <div class="col-10 details">
                            <div class="row">
                                <div class="details__box col-lg-4 col-12">
                                  <h1 class="label">Gender</h1>
                                  <div class="content p4">
                                    <p>Male</p>                     
                                  </div>
                                </div>
                             </div>
                             <div class="row">
                                <div class="details__box col-lg-4 col-12">
                                  <h1 class="label">Specialties</h1>
                                  <div class="content p4">
                                    <p>Othopedics</p>                     
                                  </div>
                                </div>
                             </div>
                             <div class="row">
                                <div class="details__box col-lg-4 col-12">
                                  <h1 class="label">Self Declared Practice Focus</h1>
                                  <div class="content p4">
                                    <p>Orthopedics</p>                     
                                  </div>
                                </div>
                             </div>
                             <div class="row">
                                <div class="details__box col-lg-4 col-12">
                                  <h1 class="label">Hospital Affiliation</h1>
                                  <div class="content p4">
                                    <p>Rowan</p>                     
                                  </div>
                                </div>
                             </div>
                             <div class="row">
                                <div class="details__box col-lg-4 col-12">
                                  <h1 class="label">Medical Group</h1>
                                  <div class="content p4">
                                    <p>Blue Cross</p>                     
                                  </div>
                                </div>
                             </div>
                        </div>
                    </div>
                  </mat-tab>
              </mat-tab-group>
    </div>
  </section>

</section>
<app-dynamic-footer></app-dynamic-footer>