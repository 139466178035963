import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CheckBoxSelectedArgs, CheckboxOption, Option, ToggleFilter } from 'src/app/shared/classes/option';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  loading = true;
  genderOptions: Option[] = [];
  languageOptions: CheckboxOption[] = [];
  providerTypeOptions: CheckboxOption[] = [];
  hospitalAffiliationOptions: CheckboxOption[] = [];
  specialtyOptions: CheckboxOption[] = [];
  toggleFilters: ToggleFilter[] = [];

  constructor (private readonly breakpointObserver: BreakpointObserver, private readonly cd: ChangeDetectorRef, private readonly formBuilder: FormBuilder) {
  }

  ngOnInit (): void {
    this.formGroup = this.formBuilder.group({
      facilityName: '',
      phoneNumber: '',
      providerIdentifier: '',
      taxId: '',
      gender: '',
      providerLanguages: new FormArray([]),
      providerTypes: new FormArray([]),
      affiliations: new FormArray([]),
      specialty: new FormArray([]),
      location: ['', Validators.required],
      relatedSpecialties: new FormArray([]),
      fellowship: new FormArray([]),
      practiceFocus: new FormArray([])
    });

    this.configureInputOptions();
    this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.toggleFilters.forEach(filter => {
          filter.isCollapsed = true;
        });
      } else {
        this.toggleFilters.forEach(filter => {
          filter.isCollapsed = false;
        });
      }
    });
    this.loading = false;
    this.cd.detectChanges();
  }

  configureInputOptions (): void {
    this.configureGenderOptions();
    this.configureLanguageOptions();
    this.configureProviderTypeOptions();
    this.configureHospitalAffiliationOptions();
    this.configureSpecialtyOptions();
    this.configureToggles();
  }

  configureToggles (): void {
    for (let i = 0; i < 12; i++) {
      this.toggleFilters.push(new ToggleFilter(i, true));
    }
  }

  get ordersFormArray (): FormArray {
    return this.formGroup.controls.providerLanguages as FormArray;
  }

  configureSpecialtyOptions (): void {
    this.specialtyOptions = [
      { value: 100, label: 'Specialty 1', checked: false },
      { value: 101, label: 'Specialty 2', checked: false },
      { value: 102, label: 'Specialty 3', checked: false },
      { value: 103, label: 'Specialty 4', checked: false },
      { value: 104, label: 'Specialty 5', checked: false }
    ] as unknown as CheckboxOption[];
  }

  configureGenderOptions (): void {
    this.genderOptions.push(new Option('Male', 'm'));
    this.genderOptions.push(new Option('Female', 'f'));
  }

  configureLanguageOptions (): void {
    this.languageOptions = [
      { value: 101, label: 'Spanish', checked: false },
      { value: 102, label: 'Chinese', checked: false },
      { value: 103, label: 'French', checked: false },
      { value: 104, label: 'Korean', checked: false },
      { value: 105, label: 'Viatnamese', checked: false }
    ] as unknown as CheckboxOption[];
  }

  configureProviderTypeOptions (): void {
    this.providerTypeOptions = [
      { value: 1314, label: 'Existing Patients Only', checked: false },
      { value: 5657, label: 'By Referral Only', checked: false }
    ] as unknown as CheckboxOption[];
  }

  configureHospitalAffiliationOptions (): void {
    this.hospitalAffiliationOptions = [
      { value: 101, label: 'Affiliation 1', checked: false },
      { value: 102, label: 'Affiliation 1', checked: false },
      { value: 103, label: 'Affiliation 1', checked: false },
      { value: 1004, label: 'Affiliation 1', checked: false }
    ] as unknown as CheckboxOption[];
  }

  checkBoxSelection (args: CheckBoxSelectedArgs, id: string): void {
    const control = <UntypedFormArray> this.formGroup.get(id);

    if (args.event?.checked) {
      control.push(new UntypedFormControl(args.option?.value));
    } else {
      const i = control.controls.findIndex(x => x.value === args.option?.value);
      control.removeAt(i);
    }
    console.log(this.formGroup.value);
  }

  toggleFiltersControls (index: number): void {
    this.toggleFilters.forEach(toggle => {
      if (toggle.index === index) {
        toggle.isCollapsed = !toggle.isCollapsed;
      } else {
        toggle.isCollapsed = true;
      }
    });
  }

  resetFilters (): void {
    Object.keys(this.formGroup.controls).forEach(key => {
      if (this.formGroup.controls[key] instanceof UntypedFormArray) {
        this.removeFormArrayValues(this.formGroup.controls[key] as UntypedFormArray);
      } else {
        this.formGroup.controls[key].setValue('');
      }
    });
    this.formGroup.reset();
    this.cd.detectChanges();
  }

  removeFormArrayValues (group: UntypedFormArray): void {
    for (let i = 0; i < group.length; i++) {
      group.removeAt(i);
    }
  }

  search (): void {
    console.log(this.formGroup.value);
  }
}
