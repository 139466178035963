import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AngularMaterialModule } from './angular-material/angular-material.module';
import { MiscModule } from './misc/misc.module';
import { SkeletonTemplateComponent } from './skeletons/skeleton-template.component';
import { BootstrapModule } from './bootstrap/bootrap.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { HelpersModule } from './helpers/helpers.module';
import { ComponentModule } from './components/component.module';
import { LocalSessionService } from './services/sessions.service';
import { IconModule } from './icons/icon.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    SkeletonTemplateComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    BootstrapModule,
    MiscModule,
    HelpersModule,
    ComponentModule,
    NgxSkeletonLoaderModule,
    IconModule,
    FormsModule,
    ReactiveFormsModule

  ],
  exports: [
    // Module Exports
    CommonModule,
    MiscModule,
    HelpersModule,
    BootstrapModule,
    AngularMaterialModule,
    ComponentModule,
    IconModule,
    FormsModule,
    ReactiveFormsModule,

    // Shared Component Exports
    SkeletonTemplateComponent
  ],
  providers: [LocalSessionService]
})
export class SharedModule { }
