import { HttpHeaders } from '@angular/common/http';

export class HttpClientBaseService {
  protected static readonly jsonOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  protected static readonly textOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    // ANGULAR - To get rid of error: Type '"text"' is not assignable to type '"json"'.
    responseType: 'text' as 'json'
  };

  protected url = '';

  constructor (url: string) {
    this.url = url;
  }

  buildUrl (path = ''): string {
    return this.url + path;
  }
}
