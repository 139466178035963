import { NgModule, inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProviderDetailsModule } from './provider-details/provider-details.module';
import { ProviderResultsModule } from './provider-results/provider-results.module';
import { ProviderSearchModule } from './provider-search/provider-search.module';
import { UserConfigurationService } from '../auth/services/user-configuration.service';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { AdvancedSearchModule } from './advanced-search/advanced-search.module';

export const configurationResolver: ResolveFn<any> =
    (route: ActivatedRouteSnapshot) => {
      const configurationService = inject(UserConfigurationService);
      configurationService.setClientKey(route.paramMap.get('clientKey') ?? '');

      return configurationService.loadConfiguration().subscribe(() => { console.log('Loaded'); });
    };

const routes: Routes = [
  {
    path: ':clientKey',
    component: HomeComponent,
    children: [
      { path: '', redirectTo: 'provider-search', pathMatch: 'full' },
      {
        path: 'provider-search',
        loadChildren: () => ProviderSearchModule
      },
      {
        path: 'provider-results',
        loadChildren: () => ProviderResultsModule
      },
      {
        path: 'provider-details',
        loadChildren: () => ProviderDetailsModule
      },
      {
        path: 'disclaimer',
        component: DisclaimerComponent
      },
      {
        path: 'advanced-search',
        loadChildren: () => AdvancedSearchModule
      }
    ],
    resolve: { data: configurationResolver }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AnthemRoutingModule { }
