import { Injectable } from '@angular/core';
import { HttpClientBaseService } from '../../shared/services/http-client-base.service';
import { AppHttpClientService } from 'src/app/shared/misc/app-http-client/app-http-client.service';
import { Observable } from 'rxjs';
import { ServiceResponse } from 'src/app/shared/classes/service-response';
import { ProviderDetails, ProviderFilter, ProviderSearchResults } from './provider-results.model';
import { ResultsResponse } from 'src/app/shared/classes/results-view';
import { IDataService } from '../generic/results/results-view-base';

@Injectable({
  providedIn: 'root'
})
export class ProviderResultsService extends HttpClientBaseService implements IDataService<ProviderFilter, ProviderDetails> {
  constructor (private readonly appHttp: AppHttpClientService) {
    super('/api/v1/provider');
  }

  getProviders (): Observable<ServiceResponse<ProviderSearchResults>> {
    const pagination = {
      itemCount: 1,
      pageCount: 1,
      pageSize: 1,
      pageItemCount: 1,
      pageNumber: 1
    };
    return this.appHttp.post<ServiceResponse<ProviderSearchResults>>(this.buildUrl('/search'), pagination, false, HttpClientBaseService.jsonOptions);
  }

  // ResultsViewOptions<ProviderFilter>
  getDataForCards (options: any): Observable<ServiceResponse<ResultsResponse<ProviderFilter, ProviderDetails>>> {
    return this.appHttp.post<ServiceResponse<ResultsResponse<ProviderFilter, ProviderDetails>>>(this.buildUrl('/search'), options, false, HttpClientBaseService.jsonOptions);
  }
}
