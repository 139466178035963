import { EventEmitter, Injectable } from '@angular/core';
import { LocalSessionService } from '../shared/services/sessions.service';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  stateSavedEmitter = new EventEmitter<void>();

  constructor (private readonly sessionService: LocalSessionService) { }

  saveState (stateKey: string, object: any): void {
    const session = this.sessionService.get();
    session.setItem(stateKey, JSON.stringify(object));
    this.stateSavedEmitter.emit();
  }

  loadState (stateKey: string, object: any): any {
    const session = this.sessionService.get();
    const value: any = session.getItem(stateKey);
    if (value?.length > 0) {
      Object.assign(object, JSON.parse(value));
      return object;
    }
    return null;
  }
}
