<section class="page">

    <!-- Header  -->
    <sub-header [linkHref]="'/provider-search'" [linkText]="'Back'" [temp]="header">
       <ng-template #header>We've found <span [countUp]="viewOptions.itemCount" [duration]="5000"></span> providers</ng-template>
    </sub-header>
    <!-- End Header -->
    <search-breadcrumb-filter #breadcrumbs></search-breadcrumb-filter>

    <results-list-header-filter title="Refine your results" [filterTemplate]="filters">
        <ng-template #filters>
            <div class="container pt-5">
                <div class="row">
                    <search-input-filter (toggleClicked)="toggleFiltersControls(0)" [isCollapsed]="toggleFilters[0].isCollapsed"
                        placeHolder="Enter Hospital or Provider Name" id="facilityName" label="Name" [parentForm]="formGroup"
                        class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-input-filter>
                    <search-autocomplete-checkbox-list-filter (toggleClicked)="toggleFiltersControls(1)"
                        [isCollapsed]="toggleFilters[1].isCollapsed" placeHolder="Filter by name"
                        (optionSelected)="checkBoxSelection($event,'specialty')" [parentForm]="formGroup" id="specialty"
                        [options]="specialtyOptions" label="Specialty"
                        class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-autocomplete-checkbox-list-filter>
                        <search-autocomplete-checkbox-list-filter (toggleClicked)="toggleFiltersControls(2)"
                        [isCollapsed]="toggleFilters[2].isCollapsed" placeHolder="Filter by name"
                        (optionSelected)="checkBoxSelection($event,'relatedSpecialties')" [parentForm]="formGroup" id="relatedSpecialties"
                        [options]="specialtyOptions" label="Related Specialties"
                        class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-autocomplete-checkbox-list-filter>

                    <!-- <search-input-select-filter (toggleClicked)="toggleFiltersControls(3)"
                        placeHolder="Address, City, Zip Code or County" [hideInput]="true" label="* Location" id="location"
                        [parentForm]="formGroup" [isCollapsed]="toggleFilters[3].isCollapsed"
                        class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-input-select-filter> -->
        
        
                    <search-autocomplete-checkbox-list-filter (toggleClicked)="toggleFiltersControls(3)"
                        [isCollapsed]="toggleFilters[3].isCollapsed" placeHolder="Filter by name"
                        (optionSelected)="checkBoxSelection($event,'fellowship')" [parentForm]="formGroup" id="fellowship"
                        [options]="specialtyOptions" label="Fellowship"
                        class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-autocomplete-checkbox-list-filter>
                    <search-autocomplete-checkbox-list-filter (toggleClicked)="toggleFiltersControls(4)"
                        [isCollapsed]="toggleFilters[4].isCollapsed" placeHolder="Filter by name"
                        (optionSelected)="checkBoxSelection($event,'practiceFocus')" [parentForm]="formGroup" id="practiceFocus"
                        [options]="specialtyOptions" label="Practice Focus"
                        class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-autocomplete-checkbox-list-filter>
                    <search-autocomplete-checkbox-list-filter (toggleClicked)="toggleFiltersControls(5)"
                        [isCollapsed]="toggleFilters[5].isCollapsed" placeHolder="Filter by name"
                        (optionSelected)="checkBoxSelection($event,'providerLanguages')" id="providerLanguages"
                        [options]="languageOptions" label="Provider Languages" [parentForm]="formGroup"
                        class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-autocomplete-checkbox-list-filter>
        
                    <!-- <search-input-filter (toggleClicked)="toggleFiltersControls(7)" [isCollapsed]="toggleFilters[7].isCollapsed"
                        placeHolder="Enter Phone Number" id="phoneNumber" label="Phone Number" [parentForm]="formGroup"
                        class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-input-filter> -->

        
                    <app-search-radio-filter (toggleClicked)="toggleFiltersControls(6)"
                        [isCollapsed]="toggleFilters[6].isCollapsed" id="gender" [options]="genderOptions" label="Gender"
                        [parentForm]="formGroup" class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></app-search-radio-filter>
                    <search-checkbox-list-filter (toggleClicked)="toggleFiltersControls(7)"
                        [isCollapsed]="toggleFilters[7].isCollapsed"
                        (optionSelected)="checkBoxSelection($event,'providerTypes')" id="providerTypes"
                        [options]="providerTypeOptions" label="Provider Type" [parentForm]="formGroup"
                        class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-checkbox-list-filter>
                    <search-checkbox-list-filter (toggleClicked)="toggleFiltersControls(8)"
                        [isCollapsed]="toggleFilters[8].isCollapsed" id="affiliations"
                        (optionSelected)="checkBoxSelection($event,'affiliations')" [options]="hospitalAffiliationOptions"
                        label="Hospital Affiliations" [parentForm]="formGroup"
                        class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-checkbox-list-filter>
                </div>
            </div>
        </ng-template>

    </results-list-header-filter>

    <div class="contaier-fluid">
        <div class="row sort justify-content-center">
            <div class="col-lg-8 col-sm-10 d-inline-flex justify-content-between">
                <h4 [matMenuTriggerFor]="menu" class="mx-auto title p5">Sort by: Recommended  <mat-icon class="align-middle">keyboard_arrow_down</mat-icon></h4>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item>Best Match</button>
                    <button mat-menu-item>Distance</button>
                    <button mat-menu-item>Name</button>
                    <button mat-menu-item>City</button>
                    <button mat-menu-item>County</button>
                    <button mat-menu-item>Zip Code</button>
                  </mat-menu>
                <!-- <div class="social-share d-flex">
                    <p>Share</p>
                    <div class="d-none d-md-block">
                        <mat-icon>stay_primary_portrait</mat-icon>
                        <mat-icon>mail_outline</mat-icon>
                        <mat-icon>print</mat-icon>
                    </div>
                </div> -->
            </div>
        </div>
    </div>

    <div class="container-fluid pull-element-y-sm">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-sm-12">

                <ng-container *ngIf="loading">
                    <anthem-skeleton-template [template]="skeletonTemplateEnum.RESULT_ITEM"></anthem-skeleton-template>
                </ng-container>
                <ng-container *ngIf="!loading">
                 <card-view  *ngFor="let card of results" [cardData]="card"></card-view>        
                </ng-container>
            </div>
        </div>
        <div class="row">
            <div class="d-flex justify-content-center">
                <div class="col-lg-8 col-sm-12">
                    <ngb-pagination [collectionSize]="viewOptions.itemCount" (pageChange)="onPageChange($event)"  [maxSize]="viewOptions.pageSize" [rotate]="true" [boundaryLinks]="false"  size="lg"></ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</section>
<app-dynamic-footer></app-dynamic-footer>
