import { Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserConfigurationService } from 'src/app/auth/services/user-configuration.service';

@UntilDestroy()
@Component({
  selector: 'app-dynamic-footer',
  templateUrl: './dynamic-footer.component.html',
  styleUrls: ['./dynamic-footer.component.scss']
})
export class DynamicFooterComponent implements OnInit {
  footerValues: string[] = [];
  constructor (public userConfigService: UserConfigurationService, private readonly _sanitizer: DomSanitizer) {}

  ngOnInit (): void {
    this.userConfigService.$configLoaded.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        const footerObject = this.userConfigService.clientFooters();
        for (const prop in footerObject) {
          this.footerValues.push((footerObject as any)[prop]);
        }
      }
    });
  }

  getFooterText (html: string): string | null {
    return this._sanitizer.sanitize(SecurityContext.HTML, html);
  }
}
