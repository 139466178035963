import { LoggerSwitchEnum } from 'src/app/shared/services/logger/logger.enum';

export const environment = {
  production: false,
  baseApiUrl: 'https://qa-anthemapi.goperspecta.tech/',
  password: 'Kg]]qzspwvN#wB4U',
  userName: 'anthemWebApp',
  loggerEnabled: LoggerSwitchEnum.ON,
  appInsights: {
    key: '09a5988f-3384-460f-8eb6-7e4b0b2d54c6'
  }
};
