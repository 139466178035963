import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppHttpClientService } from './app-http-client/app-http-client.service';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    AngularMaterialModule
  ],
  providers: [
    AppHttpClientService
  ]
})
export class MiscModule { }
