import { NoFunctions } from './ts-core';

export interface IViewOptions {
  pageCount: number
  itemCount: number
  pageSize: number
  pageItemCount: number
  pageNumber: number
  sortOrder: {} | undefined
  filters: {} | undefined
}

export class ResultsResponse<T1, T2> {
  viewOptions: ResultsViewOptions<T1>;
  data: T2[] = [];

  constructor (TCreator1: new () => T1, init?: Partial<Pick<ResultsViewOptions<T1>, NoFunctions<ResultsViewOptions<T1>>>>) {
    this.viewOptions = new ResultsViewOptions<T1>(TCreator1);
    Object.assign(this, init);
  }
}

export type IFilter = Record<string, string>;

export class ResultsViewOptions<T> {
  itemCount: number;
  pageCount: number;
  pageSize: number;
  pageItemCount: number;
  pageNumber: number;
  filters: T;

  constructor (TCreator1: new () => T, init?: Partial<Pick<ResultsViewOptions<T>, NoFunctions<ResultsViewOptions<T>>>>) {
    this.itemCount = 0;
    this.pageCount = 0;
    this.pageSize = 0;
    this.pageItemCount = 0;
    this.pageNumber = 0;
    this.filters = new TCreator1();
    Object.assign(this, init);
  }

  update (init?: Partial<Pick<ResultsViewOptions<T>, NoFunctions<ResultsViewOptions<T>>>>): void {
    Object.assign(this, init);
  }
}
