import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { UserConfigurationService } from 'src/app/auth/services/user-configuration.service';
import { UserCredentialsService } from 'src/app/auth/services/user-credentials.service';
import { SearchFormComponentData } from 'src/app/shared/components/forms/search-form/search-form.component';
import { SearchTypeEnum } from 'src/app/shared/components/forms/search-form/search-form.model';
import { SearchRequest } from './provider-search.model';
import { SearchService } from '../search/search.service';
import { Option } from 'src/app/shared/classes/option';

@Component({
  selector: 'provider-search',
  templateUrl: './provider-search.component.html',
  styleUrls: ['./provider-search.component.scss']
})
export class ProviderSearchComponent implements OnInit {
  inidcatorPositions = ['15%', '49%', '81%'];
  indicatorPosition = '';
  loading = true;

  specialties: Option[] = [];

  taxTabFormGroup = new FormGroup({});
  specialtyTabFormGroup = new FormGroup({});
  nameTabFormGroup = new FormGroup({});

  taxTabFormComponentData: SearchFormComponentData | undefined;
  specialtyTabFormComponentData: SearchFormComponentData | undefined;
  nameTabFormComponentData: SearchFormComponentData | undefined;

  constructor (private readonly searchService: SearchService, private readonly cred: UserCredentialsService, private readonly router: Router, private readonly cd: ChangeDetectorRef, private readonly userConfigService: UserConfigurationService) { }

  ngOnInit (): void {
    this.specialties = [];
    this.specialtyTabFormComponentData = new SearchFormComponentData(this.specialtyTabFormGroup, SearchTypeEnum.SPECIALTY, true, false, this.specialties);
    this.taxTabFormComponentData = new SearchFormComponentData(this.taxTabFormGroup, SearchTypeEnum.TAXID, true, true);
    this.nameTabFormComponentData = new SearchFormComponentData(this.nameTabFormGroup, SearchTypeEnum.NAME, true, false);
    this.indicatorPosition = this.inidcatorPositions[1];
    this.loading = false;
    this.cd.detectChanges();
    // this.specialtiesService.getSpecialties().pipe(untilDestroyed(this)).subscribe((value) => {
    // });
  }

  searchProviders (searchType: SearchTypeEnum): void {
    // Temporary not the full implementation
    const request = new SearchRequest();
    if (searchType === SearchTypeEnum.TAXID) {
      const address = this.taxTabFormGroup.get('address');
      request.searchLocation = address?.value ?? '';
    }
    if (searchType === SearchTypeEnum.SPECIALTY) {
      const address = this.specialtyTabFormGroup.get('address');
      request.searchLocation = address?.value ?? '';
    }
    if (searchType === SearchTypeEnum.NAME) {
      const address = this.nameTabFormGroup.get('address');
      request.searchLocation = address?.value ?? '';
    }
    this.searchService.setSearchRequestOptions(request);
    this.router.navigate([`/${this.userConfigService.getClientKey()}/provider-results`]);
  }

  onTabChange (event: MatTabChangeEvent): void {
    this.indicatorPosition = this.inidcatorPositions[event.index];
    this.cd.detectChanges();
  }

  navigateToAdvancedSearch (): void {
    this.router.navigateByUrl(`/${this.userConfigService.getClientKey()}/advanced-search`);
  }
}
