import { Injectable, isDevMode } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LoggerLevel, LoggerSwitchEnum } from './logger.enum';
export abstract class logger {
  log: any;
  info: any;
  warn: any;
  error: any;
  debug: any;
}

@Injectable()
export class LoggerService implements logger {
  log (message: any, ...optionalParams: any[]): void {
    LoggerService.writeLog(LoggerLevel.LOG, message, optionalParams);
  }

  info (message: any, ...optionalParams: any[]): void {
    LoggerService.writeLog(LoggerLevel.INFO, message, optionalParams);
  }

  warn (message: any, ...optionalParams: any[]): void {
    LoggerService.writeLog(LoggerLevel.WARN, message, optionalParams);
  }

  error (message: any, ...optionalParams: any[]): void {
    LoggerService.writeLog(LoggerLevel.ERROR, message, optionalParams);
  }

  debug (message: any, ...optionalParams: any[]): void {
    LoggerService.writeLog(LoggerLevel.DEBUG, message, optionalParams);
  }

  private static logErrorToServer (): void {
    // sends error and associated information to server to be stored.
    console.log('error will be sent straight to the server');
  }

  private static writeLog (level: LoggerLevel, message: any, optionalParams: any[]): void {
    if (!isDevMode() && level === LoggerLevel.ERROR) {
      this.logErrorToServer();
    } else if (environment.loggerEnabled === LoggerSwitchEnum.ON) {
      switch (level) {
        case LoggerLevel.DEBUG:
          console.debug('debug logged', message, optionalParams);
          break;
        case LoggerLevel.ERROR:
          console.error('error logged', message, optionalParams);
          break;
        case LoggerLevel.INFO:
          console.info('info logged', message, optionalParams);
          break;
        case LoggerLevel.LOG:
          console.log('log logged', message, optionalParams);
          break;
        case LoggerLevel.WARN:
          console.warn('warn logged', message, optionalParams);
          break;
        default:
          break;
      }
    }
  }
}
