export class PublicLoginRequest {
  userName = '';
  password = '';
}

export interface UserConfiguration {
  header: IHeader
  footer: IFooter
}

export interface IHeader {
  clientLogo: string
  networkLogo: string
  anthemLogo: string
  networkName: string
}

export interface IFooter {
  footers: IFooterTypes
}

export interface IFooterTypes {
  FOOTER_DEFAULT: string
  FOOTER_MEDICAL_ACCESS: string
  FOOTER_MPN_APPLICANT: string
  FOOTER_MPN_CONTACT: string
  FOOTER_MPN_INFO: string
  FOOTER_MPN_NOTICE: string
  FOOTER_PHARMACY: string
  FOOTER_WORKERS_COMP: string
}

export enum HttpErrorCodes {
  BADREQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  TIMEOUT = 408,
  INTERNAL = 500,
  SERVICEUNAVAILABLE = 503
}
