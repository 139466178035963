import { Component, Input } from '@angular/core';

export enum SkeletonTemplateEnum {
  RESULT_ITEM = 0,
  DETAILS,
  FAQ
}

@Component({
  selector: 'anthem-skeleton-template',
  templateUrl: './skeleton-template.component.html'
})
export class SkeletonTemplateComponent {
  @Input() template: SkeletonTemplateEnum | undefined;
  skeletonTemplateEnum = SkeletonTemplateEnum;
  config = {
    'background-color': '#e5e4e5'
  };

  customConfig (params: object): object {
    return { ...this.config, ...params };
  }
}
