<sub-header [headerName]="'Advanced Search'" [linkHref]="'/provider-search'" [linkText]="'Back'"></sub-header>

<section *ngIf="!loading" id="advanced-search" class="pt-3 pb-3 position-relative">
    <div class="container">
        <div class="row">
            <search-input-filter (toggleClicked)="toggleFiltersControls(0)" [isCollapsed]="toggleFilters[0].isCollapsed"
                placeHolder="Enter Hospital or Provider Name" id="facilityName" label="Name" [parentForm]="formGroup"
                class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-input-filter>
            <search-autocomplete-checkbox-list-filter (toggleClicked)="toggleFiltersControls(1)"
                [isCollapsed]="toggleFilters[1].isCollapsed" placeHolder="Filter by name"
                (optionSelected)="checkBoxSelection($event,'specialty')" [parentForm]="formGroup" id="specialty"
                [options]="specialtyOptions" label="Specialty"
                class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-autocomplete-checkbox-list-filter>
            <search-input-select-filter (toggleClicked)="toggleFiltersControls(2)"
                placeHolder="Address, City, Zip Code or County" label="* Location" id="location"
                [parentForm]="formGroup" [isCollapsed]="toggleFilters[2].isCollapsed"
                class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-input-select-filter>


            <search-autocomplete-checkbox-list-filter (toggleClicked)="toggleFiltersControls(3)"
                [isCollapsed]="toggleFilters[3].isCollapsed" placeHolder="Filter by name"
                (optionSelected)="checkBoxSelection($event,'fellowship')" [parentForm]="formGroup" id="fellowship"
                [options]="specialtyOptions" label="Fellowship"
                class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-autocomplete-checkbox-list-filter>
            <search-autocomplete-checkbox-list-filter (toggleClicked)="toggleFiltersControls(4)"
                [isCollapsed]="toggleFilters[4].isCollapsed" placeHolder="Filter by name"
                (optionSelected)="checkBoxSelection($event,'practiceFocus')" [parentForm]="formGroup" id="practiceFocus"
                [options]="specialtyOptions" label="Practice Focus"
                class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-autocomplete-checkbox-list-filter>
            <search-autocomplete-checkbox-list-filter (toggleClicked)="toggleFiltersControls(5)"
                [isCollapsed]="toggleFilters[5].isCollapsed" placeHolder="Filter by name"
                (optionSelected)="checkBoxSelection($event,'providerLanguages')" id="providerLanguages"
                [options]="languageOptions" label="Provider Languages" [parentForm]="formGroup"
                class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-autocomplete-checkbox-list-filter>

            <search-input-filter (toggleClicked)="toggleFiltersControls(6)" [isCollapsed]="toggleFilters[6].isCollapsed"
                placeHolder="Enter Phone Number" id="phoneNumber" label="Phone Number" [parentForm]="formGroup"
                class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-input-filter>
            <search-input-filter (toggleClicked)="toggleFiltersControls(7)" [isCollapsed]="toggleFilters[7].isCollapsed"
                placeHolder="Enter National Identifier" id="providerIdentifier" label="NPI" [parentForm]="formGroup"
                class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-input-filter>
            <search-input-filter (toggleClicked)="toggleFiltersControls(8)" [isCollapsed]="toggleFilters[8].isCollapsed"
                placeHolder="Enter Tax Identification Number" id="taxId" label="Tax Id" [parentForm]="formGroup"
                class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-input-filter>

            <app-search-radio-filter (toggleClicked)="toggleFiltersControls(9)"
                [isCollapsed]="toggleFilters[9].isCollapsed" id="gender" [options]="genderOptions" label="Gender"
                [parentForm]="formGroup" class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></app-search-radio-filter>
            <search-checkbox-list-filter (toggleClicked)="toggleFiltersControls(10)"
                [isCollapsed]="toggleFilters[10].isCollapsed"
                (optionSelected)="checkBoxSelection($event,'providerTypes')" id="providerTypes"
                [options]="providerTypeOptions" label="Provider Type" [parentForm]="formGroup"
                class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-checkbox-list-filter>
            <search-checkbox-list-filter (toggleClicked)="toggleFiltersControls(11)"
                [isCollapsed]="toggleFilters[11].isCollapsed" id="affiliations"
                (optionSelected)="checkBoxSelection($event,'affiliations')" [options]="hospitalAffiliationOptions"
                label="Hospital Affiliations" [parentForm]="formGroup"
                class="col-12 col-md-6 col-lg-4 pt-3 mb-lg-3"></search-checkbox-list-filter>
        </div>

        <div class="row box-shadow bg-white pb-5 d-sm-block d-md-none fixed-bottom">
            <div class="col-12 d-flex justify-content-between pt-3 pb-3">
                <a class="button-text button-text--green ms-3 my-auto h2" (click)="resetFilters()">Reset Filters</a>
                <button type="button"[disabled]="formGroup.invalid"(click)="search()"
                    class="button button--green my-auto button--small me-3 h2">Search</button>
            </div>
        </div>


        <div class="row pt-5 pb-5  	d-none d-md-block ">
            <div class="col-12 d-flex justify-content-center pt-3 pb-3">
                <a class="button-text button-text--green me-3 my-auto h2" (click)="resetFilters()">Reset Filters</a>
                <button type="button" [disabled]="formGroup.invalid" (click)="search()"
                    class="button button--green my-auto button--small me-3 h2">Search</button>
            </div>
        </div>

    </div>
</section>
<app-dynamic-footer></app-dynamic-footer>