import { Component, Input } from '@angular/core';
import { SearchFilterBaseComponent } from '../search-base-filter.component';
import { Option } from '../../../classes/option';

@Component({
  selector: 'search-input-select-filter',
  templateUrl: './search-input-select-filter.component.html',
  styleUrls: ['./search-input-select-filter.component.scss']
})
export class SearchInputSelectFilterComponent extends SearchFilterBaseComponent<Option> {
  @Input() hideInput = false;
}
