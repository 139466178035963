import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { IStringArray } from './ts-core';

export class FormErrorMessages {
  private static readonly errorMessages: IStringArray = {
    required: () => 'This field is required',
    validateDate: () => 'Invalid date',
    email: () => 'Not a valid email'
  };

  public getMessage (control: UntypedFormControl | AbstractControl): string {
    if (control.errors == null) return '';
    const error = Object.keys(control.errors)[0];
    return FormErrorMessages.errorMessages[error]();
  }
}
