import { Injectable } from '@angular/core';
import { ToastNotificationEvent } from './toast.model';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ToastService {
  events: Observable<ToastNotificationEvent>;
  private readonly _events = new Subject<ToastNotificationEvent>();

  constructor () {
    this.events = this._events.asObservable();
  }

  showToast (data: ToastNotificationEvent): void {
    this._events.next(data);
  }
}
