<section *ngIf="!loading" class="provider-search-wrapper d-flex align-items-center">
    <div class="container">
        <div class="search-box">
            <div class="row">
                <h5 class="text-center">
                    Find Care
                </h5>
            </div>
            <div class="indicator d-none d-md-block" [ngStyle]="{'left': indicatorPosition}"></div>
            <mat-tab-group class="pt-5" dynamicHeight="true" [selectedIndex]="1"
                (selectedTabChange)="onTabChange($event)">

                <mat-tab class="search-box__tab-wrapper">
                    <ng-template mat-tab-label>
                        <img class="search-box__tab-icon" src="../../../assets/Svg/Anthem_IDSearch_Icon.svg">
                        <h2 class="search-box__tab-label my-auto">Tax ID/NPI</h2>
                    </ng-template>
                    <div class="search-box__form mt-5">
                        <app-search-form [data]="taxTabFormComponentData"
                            (submitForm)="searchProviders($event)"></app-search-form>
                    </div>
                </mat-tab>
                <mat-tab class="search-box__tab-wrapper">
                    <ng-template mat-tab-label>
                        <img class="search-box__tab-icon" src="../../../assets/Svg/Anthem_SpecialtySearch_Icon.svg">
                        <h2 class="search-box__tab-label my-auto">Specialty</h2>
                    </ng-template>
                    <div class="search-box__form mt-5">
                        <app-search-form [data]="specialtyTabFormComponentData"
                            (submitForm)="searchProviders($event)"></app-search-form>
                    </div>
                </mat-tab>
                <mat-tab class="search-box__tab-wrapper">
                    <ng-template mat-tab-label>
                        <img class="search-box__tab-icon" src="../../../assets/Svg/Anthem_NameSearch_Icon.svg">
                        <h2 class="search-box__tab-label my-auto">Name</h2>
                    </ng-template>
                    <div class="search-box__form mt-5">
                        <app-search-form [data]="nameTabFormComponentData"
                            (submitForm)="searchProviders($event)"></app-search-form>
                    </div>
                </mat-tab>
            </mat-tab-group>
            <div class="search-box__advanced-search">
                <a (click)="navigateToAdvancedSearch()" class="p3 p3--medium">Advanced Search Options <mat-icon
                        class="align-middle">keyboard_arrow_right</mat-icon></a>
            </div>
        </div>
    </div>

</section>


<section class="additional-resources">
    <div class="row u-center-text">
        <div class="additional-resources__header text-center">
            <h4>Additional Resources</h4>
        </div>
    </div>
    <div class="container">
        <div class="additional-resources__content row">
            <div class="col-lg-4"><button class="button button--blue mb-2 p5 p5--medium"><img class="button__icon"
                        src="../../../assets/Svg/Anthem_RegionalListings_Icon.svg"> Regional Area Listings</button>
            </div>
            <div class="col-lg-4"><button class="button button--blue mb-2 p5 p5--medium"><img class="button__icon"
                        src="../../../assets/Svg/Anthem_Roster_Icon.svg">Roster of all Treating Providers</button></div>
            <div class="col-lg-4"><button class="button button--blue mb-2 p5 p5--medium"><img class="button__icon"
                        src="../../../assets/Svg/Anthem_Roster_Icon.svg">Roster of all Treating physicians</button>
            </div>
        </div>
    </div>

</section>
<app-dynamic-footer></app-dynamic-footer>