
export class ToastNotificationEvent {
  type = ToastNotificationTypeEnum.UNDEFINED;
  autoHide = false;
  delay = 0;
  message = '';
  title = '';
}

export enum ToastNotificationTypeEnum {
  UNDEFINED = -1,
  WARNING,
  GENERAL,
  SUCCESS,
  DANGER
}

export const NotificationTypeClassMap: ReadonlyMap<ToastNotificationTypeEnum, string> = new Map<ToastNotificationTypeEnum, string>([
  [ToastNotificationTypeEnum.DANGER, 'danger-toast'],
  [ToastNotificationTypeEnum.SUCCESS, 'success-toast'],
  [ToastNotificationTypeEnum.WARNING, 'warning-toast'],
  [ToastNotificationTypeEnum.GENERAL, 'general-toast']
]);
