<div class="container">
    <div class="row">
      <div class="col-md-6 align-self-center">
        <img src="../../../../assets/backgrounds/page-not-found.jpg" class="img-fluid" alt="page not found">
      </div>
      <div class="col-md-6 align-self-center">
        <h1>404</h1>
        <h2>UH OH! You're lost.</h2>
        <p>The page you are looking for does not exist. But you can click the button below
          to go back to the homepage.
        </p>
        <button class="btn btn-primary">HOME</button>
      </div>
    </div>
  </div>