import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppHttpClientService } from 'src/app/shared/misc/app-http-client/app-http-client.service';
import { HttpClientBaseService } from 'src/app/shared/services/http-client-base.service';
import { HelpInformation } from './global-faq.model';
import { ServiceResponse } from 'src/app/shared/classes/service-response';

@Injectable()
export class GlobalFaqService extends HttpClientBaseService {
  constructor (private readonly appHttp: AppHttpClientService) {
    super('/api/v1/client');
  }

  private readonly _toggleMenu = new Subject<boolean>();
  toggleMenu$ = this._toggleMenu.asObservable();

  toggleFaqMenu (isShown: boolean): void {
    this._toggleMenu.next(isShown);
  }

  loadHelpInformation (): Observable<ServiceResponse<HelpInformation>> {
    return this.appHttp.get<ServiceResponse<HelpInformation>>(this.buildUrl('/GetHelpInformation'), false);
  }
}
