export class Button {
  name: string;
  value: number;
  icon: string;
  tooltip: string;
  classes: string;

  constructor (name: string, value: number, icon: string, tooltip: string, classes: string) {
    this.name = name;
    this.value = value;
    this.icon = icon;
    this.tooltip = tooltip;
    this.classes = classes;
  }
}

export class ListItem {
  value: string;
  icon: string;
  iconClasses: string;
  elementClasses: string;
  isLink: boolean;
  isDynamicLink: boolean;
  clickValue: number;
  href: string;

  constructor (value: string, icon: string, isLink: boolean, isDynamicLink: boolean, clickValue: number, href: string, iconClasses: string, elementClasses: string) {
    this.value = value;
    this.icon = icon;
    this.isLink = isLink;
    this.isDynamicLink = isDynamicLink;
    this.clickValue = clickValue;
    this.href = href;
    this.iconClasses = iconClasses;
    this.elementClasses = elementClasses;
  }
}

export class CardHeader {
  icon = '';
  title = '';
  subTitle = '';
  buttons: Button[] = [];

  addButton (name: string, value: number, icon = '', tooltip = '', classes = ''): void {
    this.buttons.push(new Button(name, value, icon, tooltip, classes));
  }
}

export class CardBodySection {
  title: string;
  list: ListItem[] = [];

  constructor (title = '') {
    this.title = title;
  }

  addListItem (value: string, icon: string, isLink: boolean, isDynamicLink: boolean, clickValue: number, href = '', iconClasses = '', elementClasses = ''): void {
    this.list.push(new ListItem(value, icon, isLink, isDynamicLink, clickValue, href, iconClasses, elementClasses));
  }
}

export class Section {
  content: CardBodySection[] = [];
}

export class Card {
  header = new CardHeader();
  body: Section[] = [];
}
