<mat-card *ngIf="cardData" class="mb-5 card">
    <div class="row">
        <div class="col-3 col-md-1 d-none d-md-block text-center mt-4">
            <div class="card__icon-wrapper"><img class="card__icon img-fluid" [src]="getImagePath(cardData.header.icon)"
                    alt=""></div>
        </div>
        <div class="col-12 col-md-11">
            <mat-card-header class="card__card-header d-lg-flex justify-content-lg-between">
                <div class="card__icon-wrapper d-md-none d-lg-none mx-2" mat-card-avatar><img class="card__icon img-fluid"
                        [src]="getImagePath(cardData.header.icon)" alt=""></div>
                <mat-card-title class="card__card-header-title">
                    <h4 class="name"><a (click)="navigateToDetails()" href="javascript:;">{{cardData.header.title}}</a></h4>
                </mat-card-title>
                <mat-card-subtitle
                    class="card__card-header-subtitle p5 p5--medium"><strong>Orthopedic Surgery</strong> <br /> Plastic & Reconstructive Surgery</mat-card-subtitle>
                <div class="card__card-header-icons d-none d-md-block">
                    <button *ngFor="let button of cardData.header.buttons"
                        [ngClass]="button.classes" class="me-3"><img class="icon"
                            [src]="getImagePath(button.icon)" [alt]="button.tooltip"></button>
                </div>
            </mat-card-header>
            <div class="row">
                <mat-card-content class="d-lg-flex">
                    <ng-container *ngFor="let section of cardData.body; let i = index;">
                        <div class="col-12 col-md-3 card__detail" [(ngbCollapse)]="i === 0 ? false : isCollapsed">
                            <ng-container *ngFor="let content of section.content; let cIndex = index">
                                <label class="title p3" [class.mt-5]="cIndex > 0">{{content.title}}</label>
                                <ul>
                                    <li *ngFor="let item of content.list" [ngClass]="item.elementClasses">
                                        <img *ngIf="item.icon.length > 0" [ngClass]="item.iconClasses"
                                            [src]="getImagePath(item.icon)" [alt]="item.value">
                                        <ng-container *ngIf="item.isLink">
                                            <a [href]="item.href">{{item.value}}</a>
                                        </ng-container>
                                        <ng-container *ngIf="item.isDynamicLink">
                                            <span [innerHTML]="item.value | urlLinkPipe "></span>
                                        </ng-container>
                                        <ng-container *ngIf="!item.isLink && !item.isDynamicLink">
                                            {{item.value}}
                                        </ng-container>
                                    </li>
                                </ul>

                            </ng-container>
                        </div>
                        <div *ngIf="i === 0" class="col-12 col-md-3 d-md-none d-lg-none card__detail">
                            <div>
                                <a (click)="isCollapsed = !isCollapsed" href="javascript:;">+ Show More</a>
                            </div>
                        </div>
                    </ng-container>
                </mat-card-content>


                <div class="col-12 col-md-3 d-md-none d-lg-none card__detail">
                    <div class="">
                        <div class="card__card-header-icons text-center d-flex justify-content-center">
                            <button *ngFor="let button of cardData.header.buttons"
                                [ngClass]="button.classes"><img *ngIf="button.icon !== ''" class="icon me-2"
                                    [src]="getImagePath(button.icon)" [alt]="button.tooltip">{{button.name}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>