import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CardViewComponent } from './card-view/card-view.component';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { BootstrapModule } from '../bootstrap/bootrap.module';
import { HelpersModule } from '../helpers/helpers.module';
import { SearchBreadcrumbFilterComponent } from './filters/search-breadcrumb-filter/search-breadcrumb-filter.component';
import { ErrorMessageDialogComponent } from './dialogs/error-message-dialog/error-message-dialog.component';
import { MessageDialogComponent } from './dialogs/message-dialog/message-dialog.component';
import { ToastComponent } from './dialogs/toast/toast.component';
import { SearchFormComponent } from './forms/search-form/search-form.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DynamicFooterComponent } from './dynamic-footer/dynamic-footer.component';
import { GmAutocompleteComponent } from './google-maps-autocomplete/gm-autocomplete.component';
import { RouterModule } from '@angular/router';
import { AutocompleteSelectComponent } from './autocomplete-select/autocomplete-select.component';
import { AutocompleteSelectService } from './autocomplete-select/autocomplete-select.service';
import { SearchAutcompleteFilterComponent } from './filters/search-autcomplete-filter/search-autcomplete-filter.component';
import { SearchInputFilterComponent } from './filters/search-input-filter/search-input-filter.component';
import { SearchCheckboxListFilterComponent } from './filters/search-checkbox-list-filter/search-checkbox-list-filter.component';
import { SearchRadioFilterComponent } from './filters/search-radio-filter/search-radio-filter.component';
import { SearchInputSelectFilterComponent } from './filters/search-input-select-filter/search-input-select-filter.component';
import { SearchAutocompleteCheckboxListFilterComponent } from './filters/search-autocomplete-checkbox-list-filter/search-autocomplete-checkbox-list-filter.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { ResultsListHeaderFilterComponent } from './filters/results-list-header-filter/results-list-header-filter.component';

@NgModule({
  declarations: [
    CardViewComponent,
    SearchBreadcrumbFilterComponent,
    ErrorMessageDialogComponent,
    MessageDialogComponent,
    ToastComponent,
    SearchFormComponent,
    NotFoundComponent,
    DynamicFooterComponent,
    GmAutocompleteComponent,
    AutocompleteSelectComponent,
    SearchAutcompleteFilterComponent,
    SearchInputFilterComponent,
    SearchCheckboxListFilterComponent,
    SearchRadioFilterComponent,
    SearchInputSelectFilterComponent,
    SearchAutocompleteCheckboxListFilterComponent,
    SubHeaderComponent,
    ResultsListHeaderFilterComponent
  ],
  imports: [
    AngularMaterialModule,
    ReactiveFormsModule,
    BootstrapModule,
    RouterModule,
    CommonModule,
    HelpersModule,
    FormsModule
  ],
  providers: [
    AutocompleteSelectService
  ],
  exports: [CardViewComponent,
    SearchBreadcrumbFilterComponent,
    ErrorMessageDialogComponent,
    MessageDialogComponent,
    ToastComponent,
    SearchFormComponent,
    DynamicFooterComponent,
    GmAutocompleteComponent,
    AutocompleteSelectComponent,
    SearchAutcompleteFilterComponent,
    SearchInputFilterComponent,
    SearchCheckboxListFilterComponent,
    SearchRadioFilterComponent,
    SearchInputSelectFilterComponent,
    SearchAutocompleteCheckboxListFilterComponent,
    SubHeaderComponent,
    ResultsListHeaderFilterComponent]
})
export class ComponentModule { }
