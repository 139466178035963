import { Injectable } from '@angular/core';
import { UserConfiguration } from '../auth.model';
import { Observable, tap, BehaviorSubject } from 'rxjs';
import { HttpClientBaseService } from 'src/app/shared/services/http-client-base.service';
import { AppHttpClientService } from 'src/app/shared/misc/app-http-client/app-http-client.service';
import { ServiceResponse } from 'src/app/shared/classes/service-response';

@Injectable({
  providedIn: 'root'
})
export class UserConfigurationService extends HttpClientBaseService {
  configLoaded = new BehaviorSubject<boolean>(false);
  $configLoaded = this.configLoaded.asObservable();
  constructor (private readonly appHttp: AppHttpClientService) {
    super('/api/v1/client');
  }

  private configuration: UserConfiguration | undefined;
  protected clientKey!: string;

  loadConfiguration (): Observable<ServiceResponse<UserConfiguration>> {
    return this.appHttp.get<ServiceResponse<UserConfiguration>>(this.buildUrl('/GetConfiguration'), false).pipe(tap({
      next: (response: ServiceResponse<UserConfiguration>) => {
        this.configuration = {} as UserConfiguration;
        Object.assign(this.configuration, response.result);
        this.configLoaded.next(true);
      }
    }));
  }

  setClientKey (key: string): void {
    this.clientKey = key;
  }

  getClientKey (): string {
    return this.clientKey;
  }

  headerLogo (): string {
    return this.configuration != null ? this.configuration.header?.anthemLogo : '';
  }

  clientLogo (): string {
    return this.configuration != null ? this.configuration.header?.clientLogo : '';
  }

  networkName (): string {
    return this.configuration != null ? this.configuration.header?.networkName : '';
  }

  networkLogo (): string {
    return this.configuration != null ? this.configuration.header?.networkLogo : '';
  }

  clientFooters (): object {
    return this.configuration != null ? this.configuration.footer.footers : {};
  }

  showClientFooter (): boolean {
    return this.configuration != null ? Object.keys(this.configuration.footer.footers).length > 0 : false;
  }
}
