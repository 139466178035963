<form class="form" [formGroup]="parentForm">
    <div class="filter-control-wrapper ">
        <div class="filter-control-wrapper__header">
            <div class="d-flex justify-content-between"> 
                <h3 class="my-auto">{{label}}</h3>
                <mat-icon class="d-sm-block d-md-none" (click)="toggle()">keyboard_arrow_down</mat-icon>
            </div>
            <span class="selected-values d-sm-block d-md-none">{{getValues()}}</span>
        </div>
        <div class="filter-control-wrapper__content" [(ngbCollapse)]="isCollapsed">
            <div class="div mt-2 checkbox-list">
                <ul>
                    <li *ngFor="let option of options; let i = index">
                        <mat-checkbox color="primary" class="d-flex d-flex-column p5"  (change)="onSelected($event, option)" checked="{{option.checked}}" [value]="option.value" >{{option.label}}</mat-checkbox></li>
                </ul>
            </div>
        </div>
    </div>
</form>