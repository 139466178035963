import { Injectable } from '@angular/core';
import { getCookie, setCookie, removeCookie } from 'typescript-cookie';

@Injectable({
  providedIn: 'root'
})
export class UserCredentialsService {
  readonly authCookieName = 'AuthToken.Cookie';

  isUserAuthenticated (): boolean {
    const tokenCookie = getCookie(this.authCookieName);
    return tokenCookie !== null && tokenCookie !== undefined;
  }

  removeToken (): void {
    removeCookie(this.authCookieName, { path: '/' });
  }

  setToken (token: string): void {
    setCookie(this.authCookieName, token, { expires: 1, path: '/' });
  }

  getToken (): string | undefined {
    return getCookie(this.authCookieName);
  }
}
