<form class="form" [formGroup]="parentForm">
  <div class="filter-control-wrapper ">
    <div class="filter-control-wrapper__header">
      <div class="d-flex justify-content-between"> 
        <h3 class="my-auto">{{label}}</h3>
        <mat-icon class="d-sm-block d-md-none" (click)="toggle()">keyboard_arrow_down</mat-icon>
    </div>
    <span class="selected-values d-sm-block d-md-none">{{filter.value}} {{select.value}}</span>
    </div>
    <div class="filter-control-wrapper__content" [(ngbCollapse)]="isCollapsed">
      <div [hidden]="hideInput" class="position-relative form-floating">
        <input id="floating" type="text"
        class="form-control form__input form__input--border" formControlName="{{id}}" placeholder="" #filter />
        <label class="form__label p5" for="floating">{{placeHolder}}</label>
        <mat-icon (click)="filter.value = ''" *ngIf="filter.value.length > 0"  class="input-icon" svgIcon="clearInputIcon" matSuffix></mat-icon>
        <mat-icon *ngIf="showError()" class="input-icon input-icon--error"  svgIcon="inputErrorIcon"  matSuffix></mat-icon>
      </div>
      <div class="form-floating position-relative">
        <select #select [ngClass]="{'mt-5' : !hideInput}" class=" form-select form__input form__input--border" id="exampleFormControlSelect1">
          <option>5 Miles</option>
          <option>10 Miles</option>
          <option>15 Miles</option>
          <option>20 Miles</option>
        </select>
        <label for="exampleFormControlSelect1">Distance</label>
      </div>
    </div>
  </div>
</form>