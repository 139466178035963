<div class="container-fluid">
    <div class="row filter justify-content-center">
        <div class="col-lg-10 col-sm-12 d-flex">
            <span class="d-none d-md-block title my-auto p5">You've selected:</span>
            <div *ngIf="activeFilters" class="filter__list d-flex">
                <div *ngFor="let filter of activeFilters | keyvalue: returnZero" class="filter__option d-flex justify-content-between p3">{{filter.value.value}}<mat-icon *ngIf="filter.value.canRemove" (click)="clearFilter(filter.key)">close</mat-icon></div>
                <!-- <div [matMenuTriggerFor]="filterMenu" class="filter__option d-flex justify-content-between p3">3 More ...</div>

                <mat-menu class="filter-menu" #filterMenu="matMenu" xPosition="before">
                    <div class="filter__option d-flex justify-content-between">10 Miles <span>X</span></div>
                    <div class="filter__option d-flex justify-content-between">10 Miles <span>X</span></div>
                    <div class="filter__option d-flex justify-content-between">10 Miles <span>X</span></div>
                    <div class="filter__option d-flex justify-content-between">10 Miles <span>X</span></div>
                  </mat-menu> -->
            </div>
        </div>
    </div>
</div>