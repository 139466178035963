import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UrlLinkPipe } from './url-link.pipe';
import { CountUpDirective } from './count-up.directive';

@NgModule({
  declarations: [UrlLinkPipe, CountUpDirective],
  imports: [
    CommonModule
  ],
  exports: [UrlLinkPipe, CountUpDirective]
})
export class HelpersModule { }
