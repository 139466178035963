<section *ngIf="userConfigService.showClientFooter()" class="client-info container-fluid">
    <div class="row">
        <div class="col-8 mx-auto">
            <div class="row mt-3" *ngFor="let val of footerValues">
                <div class="col-12">
                    <p class="p4" [innerHTML]="getFooterText(val)"></p>
                </div>
            </div>
        </div>
    </div>
</section>