<ng-container *ngIf="template === skeletonTemplateEnum.FAQ">
        <div class="faq__header row">
            <div class="col-12">
                <ngx-skeleton-loader [theme]="customConfig({'width': '100%', 'height': '50%', 'margin-top': '2rem', 'background-color': '#5E93CA'})"  count="1" appearance="line"></ngx-skeleton-loader>
            </div>
        </div>
        <div class="row faq__content mt-5 ms-5">
            <div class="row mt-5">
                <div class="col-12">
                    <ngx-skeleton-loader [theme]="customConfig({'width': '90%', 'background-color': '#5E93CA'})"  count="5" appearance="line"></ngx-skeleton-loader>
                </div>
            </div>
        </div>

        <div class="row faq__content mt-5 ms-5">
            <div class="row mt-5">
                <div class="col-12">
                    <ngx-skeleton-loader [theme]="customConfig({'width': '25%' , 'background-color': '#5E93CA'})"  count="1" appearance="line"></ngx-skeleton-loader>
                    <ngx-skeleton-loader [theme]="customConfig({'width': '90%' , 'background-color': '#5E93CA'})"  count="5" appearance="line"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
</ng-container>




<ng-container *ngIf="template === skeletonTemplateEnum.RESULT_ITEM">
    <mat-card *ngFor="let i of [0, 1, 2, 3]" class="mb-5 card">
        <div class="row">
            <div class="col-3 col-md-1 d-none d-md-block text-center mt-2">
                <div class="card__icon-wrapper"><ngx-skeleton-loader [theme]="config" animation="progress-dark" count="1" appearance="circle"></ngx-skeleton-loader></div>
            </div>
            <div class="col-12 col-md-11">
                <mat-card-header class="card__card-header d-lg-flex justify-content-lg-between row">
                    <div class="card__icon-wrapper d-md-none d-lg-none mx-2" mat-card-avatar><ngx-skeleton-loader [theme]="config" animation="progress-dark" count="1" appearance="circle"></ngx-skeleton-loader></div>
                    <mat-card-title class="card__card-header-title">
                        <ngx-skeleton-loader [theme]="config" count="1" appearance="line"></ngx-skeleton-loader>
                    </mat-card-title>
                    <mat-card-subtitle
                        class="card__card-header-subtitle p2"><ngx-skeleton-loader [theme]="config" count="1" appearance="line"></ngx-skeleton-loader></mat-card-subtitle>
                </mat-card-header>
                <div class="row">
                    <mat-card-content class="d-lg-flex">
                        <ng-container *ngFor="let section of [0,1,2,3]; let i = index;">
                            <div class="col-12 col-md-3 card__detail">
                                <ngx-skeleton-loader [theme]="customConfig({'width': '90%'})"  count="1" appearance="line"></ngx-skeleton-loader>
                                <ul class="mb-5">
                                    <ngx-skeleton-loader [theme]="customConfig({'width': '90%'})" count="2" appearance="line"></ngx-skeleton-loader>
                                </ul>
    
                                <ngx-skeleton-loader [theme]="customConfig({'width': '90%'})" count="1" appearance="line"></ngx-skeleton-loader>
                                <ngx-skeleton-loader [theme]="customConfig({'width': '90%'})" count="3" appearance="line"></ngx-skeleton-loader>
                            </div>
                            <div *ngIf="i === 0" class="col-12 col-md-3 d-md-none d-lg-none card__detail">
                                <div>
                                    <ngx-skeleton-loader [theme]="customConfig({'width': '90%'})" count="1" appearance="line"></ngx-skeleton-loader>
                                </div>
                            </div>
                        </ng-container>
                    </mat-card-content>
    
    
                    <div class="col-12 col-md-3 d-md-none d-lg-none card__detail">
                        <div class="">
                            <div class="card__card-header-icons text-center">
                                <ngx-skeleton-loader [theme]="config" count="1" appearance="line"></ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
    <!-- <div class="box result-box__card mb-5">
        <div class="row">
            <div class="col-3 col-md-1 d-none d-md-block">
                <div class="result-box__icon-wrapper">
                    <ngx-skeleton-loader [theme]="config" animation="progress-dark" count="1" appearance="circle"></ngx-skeleton-loader>
                </div>
            </div>
            <div class="col-12 col-md-11">
                <div class="row">
                    <div class="result-box__card-header">
                        <div class="result-box__icon-wrapper d-md-none d-lg-none">
                            <img class="result-box__icon" src="../../../assets/Svg/stethoscope-alt.svg" alt="">
                        </div>
                        <div class="result-box__card-header-title">
                            <ngx-skeleton-loader [theme]="config" count="1" appearance="line"></ngx-skeleton-loader>
                        </div>
                        <div class="result-box__card-header-icons d-none d-md-block">
                            <ngx-skeleton-loader [theme]="config" count="1" appearance="line"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-3">
                        <div class="result-box__detail">
                            <ngx-skeleton-loader [theme]="config"  count="1" appearance="line"></ngx-skeleton-loader>

                            <ul>
                                <ngx-skeleton-loader [theme]="config"  count="7" appearance="line"></ngx-skeleton-loader>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="result-box__detail">
                            <ngx-skeleton-loader [theme]="config"  count="1" appearance="line"></ngx-skeleton-loader>
                            <ul class="mb-5">
                                <ngx-skeleton-loader [theme]="config" count="2" appearance="line"></ngx-skeleton-loader>
                            </ul>

                            <ngx-skeleton-loader [theme]="config" count="1" appearance="line"></ngx-skeleton-loader>
                            <ngx-skeleton-loader [theme]="config" count="3" appearance="line"></ngx-skeleton-loader>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="result-box__detail">
                            <ngx-skeleton-loader [theme]="config" count="1" appearance="line"></ngx-skeleton-loader>
                            <ul class="mb-5">
                                <ngx-skeleton-loader [theme]="config" count="2" appearance="line"></ngx-skeleton-loader>
                            </ul>

                            <ngx-skeleton-loader [theme]="config" count="1" appearance="line"></ngx-skeleton-loader>
                            <ngx-skeleton-loader [theme]="config" count="3" appearance="line"></ngx-skeleton-loader>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="result-box__detail">
                            <ngx-skeleton-loader [theme]="config" count="1" appearance="line"></ngx-skeleton-loader>
                            <ul class="mb-5">
                                <ngx-skeleton-loader [theme]="config" count="2" appearance="line"></ngx-skeleton-loader>
                            </ul>

                            <ngx-skeleton-loader [theme]="config" count="1" appearance="line"></ngx-skeleton-loader>
                            <ngx-skeleton-loader [theme]="config" count="3" appearance="line"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</ng-container>

<ng-container *ngIf="template === skeletonTemplateEnum.DETAILS">
    <div class="row justify-content-center">
        <div class="col-9 details">
            <div class="row">
                <div class="details__box col-lg-4 col-12">
                  <h3 class="label"> <ngx-skeleton-loader   [theme]="{width: '200px'}" count="1" appearance="line"></ngx-skeleton-loader></h3>
                  <div class="content">
                    <p> <ngx-skeleton-loader [theme]="config" count="4" appearance="line"></ngx-skeleton-loader></p>                     
                  </div>
                </div>
             </div>
             <div class="row">
                <div class="details__box col-lg-4 col-12">
                  <h3 class="label"> <ngx-skeleton-loader   [theme]="{width: '200px'}" count="1" appearance="line"></ngx-skeleton-loader></h3>
                  <div class="content">
                    <p> <ngx-skeleton-loader [theme]="config" count="4" appearance="line"></ngx-skeleton-loader></p>                     
                  </div>
                </div>
             </div>
             <div class="row">
                <div class="details__box col-lg-4 col-12">
                  <h3 class="label"> <ngx-skeleton-loader   [theme]="{width: '200px'}" count="1" appearance="line"></ngx-skeleton-loader></h3>
                  <div class="content">
                    <p> <ngx-skeleton-loader [theme]="config" count="4" appearance="line"></ngx-skeleton-loader></p>                     
                  </div>
                </div>
             </div>
             <div class="row">
                <div class="details__box col-lg-4 col-12">
                  <h3 class="label"> <ngx-skeleton-loader   [theme]="{width: '200px'}" count="1" appearance="line"></ngx-skeleton-loader></h3>
                  <div class="content">
                    <p> <ngx-skeleton-loader [theme]="config" count="4" appearance="line"></ngx-skeleton-loader></p>                     
                  </div>
                </div>
             </div>
             <div class="row">
                <div class="details__box col-lg-4 col-12">
                  <h3 class="label"> <ngx-skeleton-loader   [theme]="{width: '200px'}" count="1" appearance="line"></ngx-skeleton-loader></h3>
                  <div class="content">
                    <p><ngx-skeleton-loader [theme]="config" count="4" appearance="line"></ngx-skeleton-loader></p>                     
                  </div>
                </div>
             </div>
        </div>
    </div>
</ng-container>