import { NgModule } from '@angular/core';
import { NgbAccordionModule, NgbCollapseModule, NgbPaginationModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  exports: [
    NgbCollapseModule,
    NgbAccordionModule,
    NgbToastModule,
    NgbPaginationModule
  ]
})
export class BootstrapModule { }
