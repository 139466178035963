import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, debounceTime, map, startWith } from 'rxjs';
import { CheckBoxSelectedArgs, CheckboxOption } from 'src/app/shared/classes/option';
import { SearchFilterBaseComponent } from '../search-base-filter.component';
import { } from '@angular/compiler';
import { MatCheckboxChange } from '@angular/material/checkbox';
@UntilDestroy()
@Component({
  selector: 'search-autocomplete-checkbox-list-filter',
  templateUrl: './search-autocomplete-checkbox-list-filter.component.html',
  styleUrls: ['./search-autocomplete-checkbox-list-filter.component.scss']
})
export class SearchAutocompleteCheckboxListFilterComponent extends SearchFilterBaseComponent<CheckboxOption> implements OnInit {
  constructor (private readonly cd: ChangeDetectorRef) {
    super();
  }

  formGroup = new UntypedFormGroup({
    input: new UntypedFormControl('')
  });

  filteredStaticOptions: Observable<CheckboxOption[]> | undefined;

  ngOnInit (): void {
    const formControl = this.formGroup.controls.input;
    const control = <UntypedFormArray> this.parentForm?.get(this.id);
    if (this.options.length > 0) {
      this.filteredStaticOptions = formControl?.valueChanges.pipe(
        untilDestroyed(this),
        startWith(''),
        debounceTime(300),
        map(value => {
          // this.isLoading = false;
          if (value != null) {
            const values = this._filter(value);
            // if (values.length === 0) this.noMatches = true;
            return values.sort(val => {
              return control.value.includes(val.value) ? -1 : 1;
            });
          }
          return this.options.slice().sort(val => {
            return control.value.includes(val.value) ? 1 : -1;
          });
        })
      );
    }
  }

  private _filter (value: string): CheckboxOption[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.label.toLowerCase().includes(filterValue));
  }

  override onClear (): void {
    this.formGroup.controls.input.setValue('');
    this.cd.detectChanges();
  }

  override onSelected (event: MatCheckboxChange, option: CheckboxOption): void {
    this.optionSelected.emit(new CheckBoxSelectedArgs(event, option));
    // triggers observable for sorting list.
    if (this.formGroup.controls.input.value === '') this.formGroup.controls.input.updateValueAndValidity();
  }

  getValues (): string {
    const displayNames = [] as string[];
    const values = this.parentForm.get(this.id)?.value;
    const selectedOptions = this.options.filter(option => {
      return values.includes(option.value);
    });

    if (selectedOptions.length > 0) {
      selectedOptions.forEach(option => {
        displayNames.push(option.label);
      });
    }

    return displayNames.join(', ');
  }
}
