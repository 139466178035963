<div class="row h-100">
    <div class="col-12 my-auto">
        <form *ngIf="data" class="form" [formGroup]="data.formGroup">
            <div *ngIf="data.showTaxToggle" class="form__radio-group u-margin-bottom-small">
                <mat-radio-group aria-label="Select an option">
                    <mat-radio-button value="1">Tax Id Number</mat-radio-button>
                    <mat-radio-button value="2">NPI</mat-radio-button>
                </mat-radio-group>
            </div>
            <ng-container  *ngIf="data.type === searchTypeEnum.SPECIALTY">
                <div class="row">
                    <div class="col-12">
                        <input type="checkbox" id="toggleBtn">
                        <label for="toggleBtn" class="icon"><a class="button-text button-text--grey mb-3 p4"
                                (click)="toggleSpecialties($event)" [routerLink]=""><span class="toggle"></span> Most
                                Common Specialty Searches</a></label>
                    </div>
                </div>
                <div class="mb-2 row justify-content-between specialties" *ngIf="showSpecialties">
                    <div class="d-flex" @slideContentAnimation>
                        <div  class="specialties-box animated col-6 col-md-2">
                            <div class="specialties-box__icon-wrapper">
                                <img class="specialties-box__icon"
                                    src="../../../assets/Svg/Anthem_Button_Specialty_PainM_Default.svg" />
                            </div>
                            <span class="specialties-box__text">Heart Rate</span>
                        </div>
                        <div class="specialties-box animated col-6 col-md-2">
                            <div tabindex="2" class="specialties-box__icon-wrapper">
                                <img class="specialties-box__icon"
                                    src="../../../assets/Svg/Anthem_Button_Specialty_Primary_Default.svg" />
                            </div>
                            <span class="specialties-box__text">Primary Treating Physcians</span>
                        </div>
                        <div class="specialties-box animated col-6 col-md-2">
                            <div tabindex="3" class="specialties-box__icon-wrapper">
                                <img class="specialties-box__icon"
                                    src="../../../assets/Svg/Anthem_Button_Specialty_Orthopedic_Default.svg" />
                            </div>
                            <span class="specialties-box__text">Orthopedics</span>
                        </div>
                        <div class="specialties-box animated col-6 col-md-2">
                            <div tabindex="4" class="specialties-box__icon-wrapper">
                                <img class="specialties-box__icon"
                                    src="../../../assets/Svg/Anthem_Button_Specialty_Frontline_Default.svg" />
                            </div>
                            <span class="specialties-box__text">Pain Management</span>
                        </div>
                        <div class="specialties-box animated col-6 col-md-2">
                            <div tabindex="5" class="specialties-box__icon-wrapper">
                                <img class="specialties-box__icon"
                                    src="../../../assets/Svg/Anthem_Button_Specialty_Behavioral_Default.svg" />
                            </div>
                            <span class="specialties-box__text">Behavioral Health</span>
                        </div>
                    </div>
       
                </div>
            </ng-container>

            <div class="form-group row">
                <div class="col-lg-5 order-lg-1" [ngSwitch]="data.type">
                    <input *ngSwitchCase="searchTypeEnum.TAXID" formControlName="taxId" type="text" class="form__input p5"
                        placeholder="Enter Tax ID Number  / Enter NPI Number" id="tax id" required>

                    <autocomplete-select [staticOptions]="data.specialtiesTypeahead"
                        *ngSwitchCase="searchTypeEnum.SPECIALTY" [parentForm]="data.formGroup"
                        id="specialty"></autocomplete-select>

                    <input *ngSwitchCase="searchTypeEnum.NAME" formControlName="facility" type="text"
                        class="form__input p5" placeholder="Enter a Provider or Hospital/Facility Name" id="facility"
                        required>
                </div>
                <div class="col-lg-5 order-lg-2">
                    <gm-autocomplete [placeholder]="'Enter Address, City, Zipcode or County'"
                        [parentForm]="data.formGroup" id="address"></gm-autocomplete>
                </div>
                <div *ngIf="data.includeTeleHealth" class="col-lg-4 order-lg-4">
                    <div class="mt-3 mb-3">
                        <a href="javascript:;" class="button-text button-text--grey p4">Telehealth providers</a>
                    </div>
                </div>
                <div class="col-lg-2 text-center order-lg-3">
                    <button (click)="onSearch()" class="button button--green h2">Search</button>
                </div>
            </div>
        </form>
    </div>
</div>