import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Option } from '../../classes/option';
import { LoggerService } from '../../services/logger/logger.service';
import { Observable, debounceTime, map, startWith, tap } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AutocompleteSelectService } from './autocomplete-select.service';
import { FormErrorMessages } from '../../classes/form-error-messages';

@UntilDestroy()
@Component({
  selector: 'autocomplete-select',
  templateUrl: './autocomplete-select.component.html',
  styleUrls: ['./autocomplete-select.component.scss']
})
export class AutocompleteSelectComponent implements OnInit {
  @Input() parentForm = new UntypedFormGroup({});
  @Input() id = '';
  @Input() serviceUrl = '';
  @Input() staticOptions: Option[] = [];
  @Output() optionChanged: EventEmitter<any> = new EventEmitter<any>();
  errorMessages = new FormErrorMessages();

  @Input()
  get value (): string | null {
    if (this.staticOptions != null) {
      if (this._value != null) {
        const value = this._value.displayId;
        const option = this.staticOptions.find(a => a.displayId === value);
        if (option != null) {
          return option.displayId;
        }
      }
    }
    return null;
  }

  set value (id: string | null) {
    if (this.staticOptions != null) {
      const option = this.staticOptions.find(a => a.displayId === id);
      if (option != null) {
        this._value = option;
      }
    }
  }

  filteredStaticOptions: Observable<Option[]> | undefined;
  options: Option[] = [];
  isLoading = false;
  noMatches = false;

  private _value: Option | undefined;
  //   private _placeholder = '';
  //   private _required = false;
  //   private _disabled = false;

  constructor (private readonly cd: ChangeDetectorRef, private readonly logger: LoggerService, private readonly autocompleteService: AutocompleteSelectService) {}

  ngOnInit (): void {
    this.logger.log('Inside autocomplete');
    const formControl = this.parentForm.get(this.id);
    if (this.staticOptions.length > 0) {
      this.filteredStaticOptions = formControl?.valueChanges.pipe(
        untilDestroyed(this),
        startWith(''),
        debounceTime(300),
        tap(() => { this.isLoading = true; }),
        map(value => {
          this.isLoading = false;
          if (value != null) {
            const values = this._filter(value);
            if (values.length === 0) this.noMatches = true;
            return values;
          }
          return this.staticOptions.slice();
        })
      );
    }
  }

  private _filter (value: string): Option[] {
    const filterValue = value.toLowerCase();

    return this.staticOptions.filter(option => option.displayName.toLowerCase().includes(filterValue));
  }

  displayFn (value: string): string {
    if (value?.length > 0) {
      const option = this.staticOptions.find(o => o.displayId === value);
      return option?.displayName ?? '';
    }
    return '';
  }

  onOptionSelected (event: MatAutocompleteSelectedEvent): void {
    if (this.staticOptions?.length > 0) {
      const option = this.staticOptions.find(a => a.displayId === event.option.value);
      if (option != null) {
        this._value = option;
        this.parentForm.get(this.id)?.setValue(option.displayId);
      }
    }
  }

  onBlur (event: FocusEvent): void {
    if (this._value == null) {
      this.clear();
    }
  }

  onClear (): void {
    let valueChanged = false;
    if (this._value != null) valueChanged = true;
    this.clear();
    if (valueChanged) this.optionChanged.emit(new Option('', ''));
  }

  clear (): void {
    this._value = undefined;
    this.parentForm.get(this.id)?.setValue('');
  }

  onInput (event: any): void {
    this.noMatches = false;
    const option = this.staticOptions.find(a => a.displayId === event.target.value);
    if (option != null) {
      this._value = option;
    } else {
      this._value = undefined;
    }
    this.cd.detectChanges();
  }
}
