import { NgModule } from '@angular/core';
import { ProviderDetailsComponent } from './provider-details.component';
import { ProviderDetailsRoutingModule } from './provider-details-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [ProviderDetailsComponent],
  imports: [ProviderDetailsRoutingModule, SharedModule]
})
export class ProviderDetailsModule { }
